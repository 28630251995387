import axios from "./_base";
import errorHandler from "./_errorHandler";

export const gasStations = (vue, page, search, callback) => {
    search = search.trim();
    let url = `http://192.168.43.96/api/gas_stations?page=${page}`;
    if (search !== '') {
        url = `${url}&search=${search}`
    }

    axios.get(url, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error)
    });
};

export const insertGasStation = (vue, gasStation, callback, errorCallback) => {
    gasStation.blocked = gasStation.blocked ? 1: 0;
    axios.post(`http://192.168.43.96/api/insert_gas_station`, gasStation, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const updateGasStation = (vue, gasStation, callback, errorCallback) => {
    gasStation.blocked = gasStation.blocked ? 1: 0;
    axios.post(`http://192.168.43.96/api/update_gas_station/${gasStation.id}`, gasStation, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const getGasStation = (vue, id, callback, errorCallback) => {
    axios.get(`http://192.168.43.96/api/get_gas_station/${id}`, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const getEmptyGasStation = (vue, callback, errorCallback) => {
    axios.get('http://192.168.43.96/api/get_empty_gas_station', {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const deleteGasStation = (vue, id, callback, errorCallback) => {
    axios.post(`http://192.168.43.96/api/delete_gas_station`, {
        id,
    }, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const blockGasStation = (vue, id, callback, errorCallback) => {
    axios.get(`http://192.168.43.96/api/block_gas_station/${id}`, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const unBlockGasStation = (vue, id, callback, errorCallback) => {
    axios.get(`http://192.168.43.96/api/unblock_gas_station/${id}`, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};
