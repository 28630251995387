export default {
    methods: {
        scrollToFirstClass(className) {
            // https://forum.vuejs.org/t/getelementsbyclassname-and-htmlcollection-within-a-watcher/26478
            setTimeout( () => {
                const el = this.$el.getElementsByClassName(className);
                if (el.length > 0) {
                    el.item(0).scrollIntoView(true);
                    const scrolledY = window.scrollY;
                    const header = document.getElementsByTagName('header')[0];
                    const headerHeight = document.getElementsByTagName('header')[0].clientHeight;
                    const headerMarginTop = parseInt(header.style.marginTop) || 0;
                    const headerMarginBottom = parseInt(header.style.marginBottom) || 0;
                    const headerPaddingTop = parseInt(header.style.paddingTop) || 0;
                    const headerPaddingBottom = parseInt(header.style.paddingBottom) || 0;
                    const headerBorderTop = parseInt(header.style.borderTop) || 0;
                    const headerBorderBottom = parseInt(header.style.borderBottom) || 0;
                    const headerOuterHeight = headerHeight + headerMarginTop + headerMarginBottom + headerPaddingTop + headerPaddingBottom + headerBorderTop + headerBorderBottom;
                    if (scrolledY) {
                        window.scroll(0, scrolledY - headerOuterHeight - 5);
                    }
                }
            });
        },
    }
}
