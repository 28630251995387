import axios from "./_base";
import errorHandler from "./_errorHandler";

export const products = (vue, page, search, callback) => {
    search = search.trim();
    let url = `http://192.168.43.96/api/products?page=${page}`;
    if (search !== '') {
        url = `${url}&search=${search}`
    }

    axios.get(url, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error)
    });
};

export const allProducts = (vue, search, callback) => {
    search = search.trim();
    let url = `http://192.168.43.96/api/all_products`;
    if (search !== '') {
        url = `${url}?search=${search}`
    }

    axios.get(url, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error)
    });
};

export const insertProduct = (vue, product, callback, errorCallback) => {
    product.blocked = product.blocked ? 1: 0;
    axios.post(`http://192.168.43.96/api/insert_product`, product, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const updateProduct = (vue, product, callback, errorCallback) => {
    product.blocked = product.blocked ? 1: 0;
    axios.post(`http://192.168.43.96/api/update_product/${product.id}`, product, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const getProduct = (vue, id, callback, errorCallback) => {
    axios.get(`http://192.168.43.96/api/get_product/${id}`, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const getEmptyProduct = (vue, callback, errorCallback) => {
    axios.get('http://192.168.43.96/api/get_empty_product', {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const deleteProduct = (vue, id, callback, errorCallback) => {
    axios.post(`http://192.168.43.96/api/delete_product`, {
        id,
    }, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const blockProduct = (vue, id, callback, errorCallback) => {
    axios.get(`http://192.168.43.96/api/block_product/${id}`, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const unBlockProduct = (vue, id, callback, errorCallback) => {
    axios.get(`http://192.168.43.96/api/unblock_product/${id}`, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};
