<template>
  <div>

    <CModal
        color="red"
        centerer
        :show.sync="usersModalIsOpen"
        :closeOnBackdrop="true"
    >
      <div v-if="!_.isEmpty(users)">

        <div class="wy-search-input-container">
          <input
              class="form-control full-width"
              :placeholder="$t('type_here_to_search')"
              type="text"
              v-model="usersSearch"
              @input="runSearch"
              @keydown.esc="clearSearch"
          />
          <button class="wy-clear-button" @click="clearSearch" v-if="usersSearch !== ''">
            <span class="wy-icon-clear-input"></span>
          </button>
        </div>

        <div v-if="!_.isEmpty(usersErrorMessage)">
          <CAlert color="danger" class="mt-1 text-center" close-button>
            {{ usersErrorMessage }}
          </CAlert>
        </div>
        <table class="table table-hover wy-list-table">
          <thead>
          <tr>
            <th style="width: 50px;">#</th>
            <th>{{ $t('name') }}</th>
            <th>{{ $t('email') }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(user, userIndex) in users"
              :key="`user_${userIndex}`"
              :class="{ 'wy-blocked-table-row': user.blocked }">
            <td>{{ user.id }}</td>
            <td>
              {{ user.name }}
            </td>
            <td>
              {{ user.email }}
            </td>
            <td style="width: 40px; white-space: nowrap;" class="text-at-end">
              <CButton color="wy-action-button p-0" @click="addWorker(user)"><i class="wy-icon-add-worker p-0" style="font-size: 24px;"></i></CButton>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="row wy-pagination-info">
          <div class="col">
            {{ $t('from') }} {{ usersFrom }}
            {{ $t('to') }} {{ usersTo }}
          </div>
          <div class="col text-right">
            <strong>{{ $t('total') }}: {{ usersTotal }}</strong>
          </div>
        </div>

        <CPagination
            align="center"
            :pages="usersLastPage"
            :active-page.sync="usersCurrentPage"
        />
      </div>
      <div v-else>
        <InlineLoader/>
      </div>
      <template #header-wrapper>&nbsp;</template>
      <template #footer>
        <button
            type="button"
            class="btn btn-secondary"
            @click="closeUserModal"
        >
          {{ $t('close') }}
        </button>
      </template>
    </CModal>

    <CForm @submit="submit" v-if="loaded">
      <CCard>
        <CCardBody>

          <CAlert color="success text-center" v-if="!_.isEmpty(sentMessage)" @update:show="sentMessage = ''">
            {{ sentMessage }}
          </CAlert>

          <div style="display: flex; justify-content: end;">
            <div :class="{ 'text-success': !gasStation.blocked, 'text-muted': gasStation.blocked }" class="text-bold" style="font-size: 12px;">{{ gasStation.blocked ? $t('disabled') : $t('enabled') }}</div>
            &nbsp;
            <CSwitch
                class="mr-1"
                color="success"
                :checked="!gasStation.blocked"
                variant="opposite"
                size="sm"
                @update:checked="toggleBlock"
            />
          </div>

          <div class="row">
            <div class="col-md-12 col-sm-12">
              <CInput
                  :label="$t('station_name')"
                  type="text"
                  v-model="gasStation.name"
                  @keydown="resetErrors('name')"
                  :invalid-feedback="_.has(errors, 'name') ? errors.name : ''"
                  :is-valid="_.has(errors, 'name') ? false : null"
                  ref="name"
              >
              </CInput>
            </div>

          </div>

          <div class="wy-gas-station-address-block">
            <div class="wy-gas-station-address-block-title">{{ $t('address_information') }}</div>
            <div class="row">

              <div class="col-md-6 col-sm-12">
                <CInput
                    :label="$t('building_number')"
                    type="text"
                    v-model="gasStation.building_number"
                    @keydown="resetErrors('building_number')"
                    :invalid-feedback="_.has(errors, 'building_number') ? errors.building_number : ''"
                    :is-valid="_.has(errors, 'building_number') ? false : null"
                >
                </CInput>
              </div>

              <div class="col-md-6 col-sm-12">
                <CInput
                    :label="$t('street')"
                    type="text"
                    v-model="gasStation.street"
                    @keydown="resetErrors('street')"
                    :invalid-feedback="_.has(errors, 'street') ? errors.street : ''"
                    :is-valid="_.has(errors, 'street') ? false : null"
                >
                </CInput>
              </div>

              <div class="col-md-6 col-sm-12">
                <CInput
                    :label="$t('district')"
                    type="text"
                    v-model="gasStation.district"
                    @keydown="resetErrors('district')"
                    :invalid-feedback="_.has(errors, 'district') ? errors.district : ''"
                    :is-valid="_.has(errors, 'district') ? false : null"
                >
                </CInput>
              </div>

              <div class="col-md-6 col-sm-12">
                <CInput
                    :label="$t('city')"
                    type="text"
                    v-model="gasStation.city"
                    @keydown="resetErrors('city')"
                    :invalid-feedback="_.has(errors, 'city') ? errors.city : ''"
                    :is-valid="_.has(errors, 'city') ? false : null"
                >
                </CInput>
              </div>

              <div class="col-md-6 col-sm-12">
                <CInput
                    :label="$t('zip_code')"
                    type="text"
                    v-model="gasStation.zip_code"
                    @keydown="resetErrors('zip_code')"
                    :invalid-feedback="_.has(errors, 'zip_code') ? errors.zip_code : ''"
                    :is-valid="_.has(errors, 'zip_code') ? false : null"
                >
                </CInput>
              </div>

              <div class="col-md-6 col-sm-12">
                <CInput
                    :label="$t('address_secondary_number')"
                    type="text"
                    v-model="gasStation.address_secondary_number"
                    @keydown="resetErrors('address_secondary_number')"
                    :invalid-feedback="_.has(errors, 'address_secondary_number') ? errors.address_secondary_number : ''"
                    :is-valid="_.has(errors, 'address_secondary_number') ? false : null"
                >
                </CInput>
              </div>

              <div class="col-md-6 col-sm-12">
                <CInput
                    :label="$t('short_address')"
                    type="text"
                    v-model="gasStation.short_address"
                    @keydown="resetErrors('short_address')"
                    :invalid-feedback="_.has(errors, 'short_address') ? errors.short_address : ''"
                    :is-valid="_.has(errors, 'short_address') ? false : null"
                >
                </CInput>
              </div>

              <div class="col-md-6 col-sm-12">
                <CInput
                    :label="$t('gps_location_url')"
                    type="text"
                    v-model="gasStation.gps_location_url"
                    @keydown="resetErrors('gps_location_url')"
                    :invalid-feedback="_.has(errors, 'gps_location_url') ? errors.gps_location_url : ''"
                    :is-valid="_.has(errors, 'gps_location_url') ? false : null"
                >
                  <template #append>
                    <a v-show="!_.isEmpty(gasStation.gps_location_url) && validURL(gasStation.gps_location_url)"
                       :href="gasStation.gps_location_url"
                       target="_blank"
                       class="btn btn-red text-white">
                      <span class="wy-icon-gps-location" style="margin: 0; font-size: 22px;"></span>
                    </a>
                  </template>
                </CInput>
              </div>

            </div>
          </div>

          <div class="wy-gas-station-phones-block">
            <div class="wy-gas-station-phones-block-title">{{ $t('phones') }}</div>
            <span class="wy-gas-station-phones-block-add" @click="addPhone">
              <i class="wy-icon-add p-0"></i>
            </span>
            <table class="table table-sm" v-if="!this._.isEmpty(gasStation.phones)">
              <thead>
                <tr>
                  <th style="width: 40%;">{{ $t('name') }}</th>
                  <th>{{ $t('number') }}</th>
                  <th style="width: 20px;">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(phone, phoneIndex) in gasStation.phones" :key="`phone_${phoneIndex}`">
                <td>
                  <CInput
                      type="text"
                      v-model="gasStation.phones[phoneIndex].name"
                      @keydown="resetErrors(`phones.${phoneIndex}.name`)"
                      :invalid-feedback="_.has(errors, `phones.${phoneIndex}.name`) ? errors[`phones.${phoneIndex}.name`] : ''"
                      :is-valid="_.has(errors, `phones.${phoneIndex}.name`) ? false : null"
                  >
                  </CInput>
                </td>
                <td>
                  <CInput
                      type="text"
                      v-model="gasStation.phones[phoneIndex].number"
                      @keydown="resetErrors(`phones.${phoneIndex}.number`)"
                      :invalid-feedback="_.has(errors, `phones.${phoneIndex}.number`) ? errors[`phones.${phoneIndex}.number`] : ''"
                      :is-valid="_.has(errors, `phones.${phoneIndex}.number`) ? false : null"
                      class="input-ltr-direction"
                  >
                  </CInput>
                </td>
                <td style="padding-top: 12px;">
                  <CButton color="wy-action-button p-0" @click="deletePhone(phoneIndex)"><i class="wy-icon-delete p-0"></i></CButton>
                </td>
              </tr>
              </tbody>
            </table>
            <div v-else>
              <CAlert color="success" class="mt-1 text-center" v-if="!_.has(errors, 'phones')">
                {{ $t('nothing') }}
              </CAlert>
              <CAlert color="danger" class="mt-1 text-center is-invalid" v-else>
                {{ errors.phones }}
              </CAlert>
            </div>
          </div>

          <div class="wy-gas-station-phones-block">
            <div class="wy-gas-station-phones-block-title">{{ $t('workers') }}</div>
            <span class="wy-gas-station-phones-block-add" @click="openUserModal">
              <i class="wy-icon-add p-0"></i>
            </span>
            <table class="table table-sm" v-if="!this._.isEmpty(gasStation.workers)">
              <thead>
              <tr>
                <th style="width: 50px;">#</th>
                <th style="width: 40%;">{{ $t('name') }}</th>
                <th>{{ $t('position') }}</th>
                <th style="width: 20px;">&nbsp;</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(worker, workerIndex) in gasStation.workers" :key="`worker_${workerIndex}`">
                <td>{{ worker.user_id }}</td>
                <td>
                  <CInput
                      type="text"
                      :value="gasStation.workers[workerIndex].user.name"
                      :invalid-feedback="_.has(errors, `workers.${workerIndex}.user.id`) ? errors[`workers.${workerIndex}.user.id`] : ''"
                      :is-valid="_.has(errors, `workers.${workerIndex}.user.id`) ? false : null"
                      readonly
                      class="readonly-white-bg"
                  >
                  </CInput>
                </td>
                <td>
                  <CSelect
                      :value.sync="gasStation.workers[workerIndex].position"
                      :options="[
                          {
                            label: $t('supervisor'),
                            value: 'supervisor'
                          },
                          {
                            label: $t('pump_worker'),
                            value: 'pump_worker'
                          }
                      ]"
                      :placeholder="$t('please_select_a_worker_position')"
                      @click="resetErrors(`workers.${workerIndex}.position`)"
                      :invalid-feedback="_.has(errors, `workers.${workerIndex}.position`) ? errors[`workers.${workerIndex}.position`] : ''"
                      :is-valid="_.has(errors, `workers.${workerIndex}.position`) ? false : null"
                  />
                </td>
                <td style="padding-top: 12px;">
                  <CButton color="wy-action-button p-0" @click="deleteWorker(workerIndex)"><i class="wy-icon-delete p-0"></i></CButton>
                </td>
              </tr>
              </tbody>
            </table>
            <div v-else>
              <CAlert color="success" class="mt-1 text-center" v-if="!_.has(errors, 'workers')">
                {{ $t('nothing') }}
              </CAlert>
              <CAlert color="danger" class="mt-1 text-center is-invalid" v-else>
                {{ errors.workers }}
              </CAlert>
            </div>
          </div>

          <div class="wy-gas-station-phones-block">
            <div class="wy-gas-station-phones-block-title">{{ $t('tanks') }}</div>
            <span class="wy-gas-station-phones-block-add" @click="addTank">
              <i class="wy-icon-add p-0"></i>
            </span>
            <table class="table table-sm" v-if="!this._.isEmpty(gasStation.tanks)" style="max-width: 500px; margin: 0 auto;">
              <thead>
              <tr>
                <th style="width: 120px;">{{ $t('tank_number') }}</th>
                <th>{{ $t('product') }}</th>
                <th style="width: 20px;">&nbsp;</th>
                <th style="width: 20px;">&nbsp;</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(tank, tankIndex) in gasStation.tanks">
                <tr :key="`tank_${tankIndex}`" style="border-top: #3F4C65 1px dashed;">
                <td style="padding-top: 20px;">
                  <CInput
                      type="text"
                      v-model="gasStation.tanks[tankIndex].number"
                      @keydown="resetErrors(`tanks.${tankIndex}.number`)"
                      :invalid-feedback="_.has(errors, `tanks.${tankIndex}.number`) ? errors[`tanks.${tankIndex}.number`] : ''"
                      :is-valid="_.has(errors, `tanks.${tankIndex}.number`) ? false : null"
                  >
                  </CInput>
                </td>
                <td style="padding-top: 20px;">
                  <CSelect
                      :value.sync="gasStation.tanks[tankIndex].product_id"
                      :options="allProducts"
                      :placeholder="$t('please_select_a_customer_type')"
                      @click="resetErrors(`tanks.${tankIndex}.product_id`)"
                      :invalid-feedback="_.has(errors, `tanks.${tankIndex}.product_id`) ? errors[`tanks.${tankIndex}.product_id`] : ''"
                      :is-valid="_.has(errors, `tanks.${tankIndex}.product_id`) ? false : null"
                  />
                </td>
                <td style="padding-top: 26px;">
                  <CButton color="wy-action-button p-0" @click="deleteTank(tankIndex)"><i class="wy-icon-delete p-0"></i></CButton>
                </td>
                <td style="padding-top: 26px;">
                  <CButton color="wy-action-button p-0" @click="addPump(tankIndex)"><i class="wy-icon-add-pump p-0" style="font-size: 24px;"></i></CButton>
                </td>
              </tr>
                <template v-for="(pump, pumpIndex) in tank.pumps">
                  <tr :key="`pump_${tankIndex}_${pumpIndex}`" v-if="!_.isEmpty(tank.pumps)">
                    <td class="text-at-end" style="padding-top: 10px;">{{ $t('pump') }}</td>
                    <td>
                      <CInput
                          :placeholder="$t('enter_the_number_of_pump')"
                          class="pump-field"
                          type="text"
                          v-model="gasStation.tanks[tankIndex].pumps[pumpIndex].number"
                          @keydown="resetErrors(`tanks.${tankIndex}.pumps.${pumpIndex}.number`)"
                          :invalid-feedback="_.has(errors, `tanks.${tankIndex}.pumps.${pumpIndex}.number`) ? errors[`tanks.${tankIndex}.pumps.${pumpIndex}.number`] : ''"
                          :is-valid="_.has(errors, `tanks.${tankIndex}.pumps.${pumpIndex}.number`) ? false : null"
                      >
                      </CInput>
                    </td>
                    <td style="padding-top: 12px;">
                      <CButton color="wy-action-button p-0" @click="deletePump(tankIndex, pumpIndex)"><i class="wy-icon-delete p-0"></i></CButton>
                    </td>
                  </tr>
                </template>
                <template>
                  <tr :key="`pump_is_empty_${tankIndex}`" v-if="_.isEmpty(tank.pumps)">
                    <td></td>
                    <td>
                      <CAlert color="success" class="mt-1 text-center" v-if="!_.has(errors, `tanks.${tankIndex}.pumps`)">
                        {{ $t('there_are_no_pumps') }}
                      </CAlert>
                      <CAlert color="danger" class="mt-1 text-center" v-else>
                        {{ errors[`tanks.${tankIndex}.pumps`] }}
                      </CAlert>
                    </td>
                  </tr>
                </template>
              </template>
              </tbody>
            </table>
            <div v-else>
              <CAlert color="success" class="mt-1 text-center" v-if="!_.has(errors, 'tanks')">
                {{ $t('nothing') }}
              </CAlert>
              <CAlert color="danger" class="mt-1 text-center is-invalid" v-else>
                {{ errors.tanks }}
              </CAlert>
            </div>
          </div>

        </CCardBody>
        <CCardFooter>
          <div class="row">
            <div class="col">
              <CButton type="submit" size="sm" color="red" class="px-4">
                <CIcon name="cil-check-circle"/>
                {{ $t('save') }}
              </CButton>
            </div>
            <div class="col text-right">
<!--              <CButton type="reset" size="sm" color="secondary" class="px-4">-->
<!--                <CIcon name="cil-ban"/>-->
<!--                {{ $t('reset') }}-->
<!--              </CButton>-->
            </div>
          </div>
        </CCardFooter>
      </CCard>
    </CForm>
  </div>
</template>

<script>
import {getGasStation, getEmptyGasStation, insertGasStation, updateGasStation} from "../../../api/gasStation";
import {allProducts} from "../../../api/product";
import {usersForGasStation} from "../../../api/user";
import scrollToFirstClass from '../../../mixins/DashboardScrollToFirstClass';
import InlineLoader from '../../components/InlineLoader';
import _ from "lodash";

export default {
  name: 'GasStation',
  mixins: [
    scrollToFirstClass,
  ],
  components: {
    InlineLoader,
  },
  data() {
    return {
      passwordIsVisible: false,
      passwordIsVisibleConfirmation: false,
      errors: {},
      error: '',
      sentMessage: '',
      allProducts: [],
      users: [],
      //
      id: '',
      gasStation: {},
      //
      isEdit: false,
      loaded: false,
      usersModalIsOpen: false,
      userFirstPage: 0,
      usersLastPage: 0,
      usersPerPage: 0,
      usersTotal: 0,
      usersFrom: 0,
      usersTo: 0,
      usersCurrentPage: 1,
      usersSearch: '',
      usersErrorMessage: '',
    };
  },
  watch: {
    usersModalIsOpen(usersModalIsOpen) {
      if (usersModalIsOpen && this._.isEmpty(this.users)) {
        this.getUsers();
      }
    },
    usersCurrentPage() {
      this.getUsers();
    },
  },
  mounted() {
    this.tryGetGasStation();
  },
  methods: {
    clearSearch() {
      if (this.usersSearch !== '') {
        this.usersSearch = '';
        this.usersCurrentPage = 1;
        this.getUsers();
      }
    },
    runSearch: _.debounce(function() {
      this.usersCurrentPage = 1;
      this.getUsers();
    }, 500),
    getUsers() {
      this.usersErrorMessage = '';
      usersForGasStation(this, this.$route.params.gas_station_id || 0, this.usersCurrentPage, this.usersSearch, (response) => {
        this.users = response.users.data;
        this.usersLastPage = Number(response.users.last_page);
        this.usersPerPage = Number(response.users.per_page);
        this.usersTotal = Number(response.users.total);
        this.usersFrom = Number(response.users.from);
        this.usersTo = Number(response.users.to);
        this.usersLoaded = true;
        this.$forceUpdate();
      });
    },
    resetErrors(property) {
      this.sentMessage = '';
      if (!this._.isUndefined(property)) {
        delete this.errors[property];
      } else {
        this.errors = {};
        this.error = ''
      }
    },
    tryGetGasStation() {
      if (this._.has(this.$route.params, 'gas_station_id') && /^\d+$/.test(this.$route.params.gas_station_id)) {
        this.isEdit = true;
        getGasStation(this, this.$route.params.gas_station_id, (response) => {
          this.gasStation = response.gas_station;
          this.getAllProducts();
        }, (error) => {
          this.error = error.message;
        });
      } else {
        getEmptyGasStation(this, (response) => {
          this.gasStation = response.gas_station;
          this.getAllProducts();
        }, (error) => {
          this.error = error.message;
        });
      }
    },
    getAllProducts() {
      allProducts(this, '', (response) => {
        this._.forEach(response.products, (item) => {
          this.allProducts.push({
            label: item.name,
            value: item.id,
          });
        });
        this.loaded = true;
      });
    },
    validate(callback) {
      let hasErrors = false;
      this.resetErrors();

      this.$forceUpdate();
      if (!hasErrors) {
        callback()
      }
    },
    submit(event) {
      if (this._.isFunction(event.preventDefault)) {
        event.preventDefault();
      }
      this.sentMessage = '';
      this.validate(() => {
        if (!this.isEdit) {
          insertGasStation(this, this.gasStation, (response) => {
            this.sentMessage = response.message;
            this.$router.push({name: 'GasStations'});
          }, (error) => {
            if (this._.has(error, 'errors')) {
              let i = 1;
              this._.forEach(error.errors, (item, key) => {
                this.errors[key] = item[0];
                if (i === this._.size(error.errors)) {
                  this.$forceUpdate();
                  this.scrollToFirstClass('is-invalid');
                }
                i += 1;
              });
            }
          });
        } else {
          updateGasStation(this, this.gasStation, (response) => {
            this.sentMessage = response.message;
            this.$router.push({name: 'GasStations'});
          }, (error) => {
            if (this._.has(error, 'errors')) {
              let i = 1;
              this._.forEach(error.errors, (item, key) => {
                this.errors[key] = item[0];
                if (i === this._.size(error.errors)) {
                  this.$forceUpdate();
                  this.scrollToFirstClass('is-invalid');
                }
                i += 1;
              });
            }
          });
        }
      });
    },
    toggleBlock() {
      this.gasStation.blocked = !this.gasStation.blocked;
    },
    validURL(url) {
      const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))|'+ // OR ip (v4) address
          'localhost'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!pattern.test(url);
    },
    deletePhone(index) {
      this.$store.commit('confirmation', {
        message: this.$t('are_you_sure_that_you_want_to_delete_this_phone', {
          name: this.gasStation.phones[index].name,
          number: this.gasStation.phones[index].number,
        }),
        onOk: () => {
          this.resetErrors();
          this.gasStation.phones.splice(index, 1);
          this.$forceUpdate();
        }
      });
    },
    addPhone() {
      this.gasStation.phones.push({
        name: '',
        number: '',
        id: 0,
      })
    },
    addWorker(user) {
      this.usersErrorMessage = '';
      const addOneWorker = () => {
        this.gasStation.workers.push({
          user,
          position: '',
          id: 0,
        });
      };
      if (this.gasStation.workers.length > 0) {
        let hasError = false;
        let i = 1;
        this._.forEach(this.gasStation.workers, (worker) => {
          if (worker.user.id === user.id) {
            hasError = true;
          }

          if (i === this._.size(this.gasStation.workers)) {
            if (hasError) {
              this.usersErrorMessage = this.$t('this_user_is_already_in_workers_team_in_this_gas_station');
            } else {
              addOneWorker();
            }
          }
          i += 1;
        });
      } else {
        addOneWorker();
      }
    },
    deleteTank(index) {
      this.$store.commit('confirmation', {
        message: this.$t('are_you_sure_that_you_want_to_delete_this_tank', {
          number: this.gasStation.tanks[index].number || this.$t('unspecific'),
        }),
        onOk: () => {
          this.resetErrors();
          this.gasStation.tanks.splice(index, 1);
          this.$forceUpdate();
        }
      });
    },
    deleteWorker(index) {
      this.$store.commit('confirmation', {
        message: this.$t('are_you_sure_that_you_want_to_delete_this_worker', {
          name: this.gasStation.workers[index].user.name || this.$t('unspecific'),
        }),
        onOk: () => {
          this.resetErrors();
          this.gasStation.workers.splice(index, 1);
          this.$forceUpdate();
        }
      });
    },
    addTank() {
      this.gasStation.tanks.push({
        product_id: '',
        number: '',
        id: 0,
        pumps: [],
      })
    },
    deletePump(tankIndex, pumpIndex) {
      this.$store.commit('confirmation', {
        message: this.$t('are_you_sure_that_you_want_to_delete_this_pump', {
          tankNumber: this.gasStation.tanks[tankIndex].number || this.$t('unspecific'),
          pumpNumber: this.gasStation.tanks[tankIndex].pumps[pumpIndex].number || this.$t('unspecific'),
        }),
        onOk: () => {
          this.resetErrors();
          this.gasStation.tanks[tankIndex].pumps.splice(pumpIndex, 1);
          this.$forceUpdate();
        }
      });
    },
    addPump(tankIndex) {
      this.gasStation.tanks[tankIndex].pumps.push({
        tank_id: '',
        number: '',
        id: 0,
      })
    },
    closeUserModal() {
      this.usersModalIsOpen = false;
    },
    openUserModal() {
      this.usersModalIsOpen = true;
      this.usersErrorMessage = '';
    },
  },
}
</script>

<style lang="scss">
.wy-gas-station-address-block {
  background-color: #EEE;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 10px;

  .wy-gas-station-address-block-title {
    font-weight: bold;
    background-color: #FFFFFF;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    padding: 5px 15px;
    display: inline-block;
    margin: 0 0 10px 0;
  }
}
.wy-gas-station-phones-block {
  margin: 20px 0 0 0;
  background-color: #EEE;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  padding: 10px;
  position: relative;

  .wy-gas-station-phones-block-title {
    font-weight: bold;
    background-color: #FFFFFF;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    padding: 5px 15px;
    display: inline-block;
    margin: 0 0 10px 0;
  }

  .table thead th {
    border-bottom-width: 0!important;
    border-top-width: 0!important;
  }
  .table tbody td {
    border-bottom-width: 0!important;
    border-top-width: 0!important;
  }

  .wy-gas-station-phones-block-add {
    position: absolute;
    top: 10px;
    background-color: #FFFFFF;
    -webkit-border-radius: 90px;
    -moz-border-radius: 90px;
    border-radius: 90px;
    padding: 8px;
    cursor: pointer;
    [dir=ltr] & {
      right: 10px;
    }
    [dir=rtl] & {
      left: 10px;
    }
    transition: all 350ms ease;
    &:hover {
      background-color: #3462A5;
      i {
        color: #FFFFFF;
      }
    }
    i {
      transition: all 350ms ease;
      font-size: 20px;
    }
  }
}
.padding-left-0 {
  [dir=ltr] & {
    padding-left: 0;
  }
  [dir=rtl] & {
    padding-right: 0;
  }
}
.padding-right-1 {
  [dir=ltr] & {
    padding-right: 5px;
  }
  [dir=rtl] & {
    padding-left: 5px;
  }
}

.pump-field {
  input {
    background-color: #3462A5;
    color: #FFFFFF;
    &:focus {
      background-color: #3462A5;
      color: #FFFFFF;
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #EEEEEE;
      font-style: italic;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: #EEEEEE;
      font-style: italic;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: #EEEEEE;
      font-style: italic;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: #EEEEEE;
      font-style: italic;
    }
  }
}
.readonly-white-bg {
  input {
    background-color: transparent!important;
    cursor: default;
    border-color: transparent;
    border-bottom-style: dashed;
    border-bottom-color: #222222;
    &:focus {
      border-color: transparent;
      border-bottom-style: dashed;
      border-bottom-color: #222222;
    }
  }
}
.wy-search-input-container {
  position: relative;
  input {
    background-color: #F1F1F1;
    [dir=ltr] & {
      padding-right: 40px;
    }

    [dir=rtl] & {
      padding-left: 40px;
    }
  }
  button.wy-clear-button {
    position: absolute;
    background: none;
    border: none;
    top: 8px;
    color: #C41117;
    transition: color 350ms ease;
    [dir=ltr] & {
      right: 5px;
    }

    [dir=rtl] & {
      left: 5px;
    }
    span {
      margin: 0!important;
      font-size: 24px;
    }
    &:focus {
      outline: none;
    }
    &:hover {
      color: #222222;
    }
  }
}
</style>
