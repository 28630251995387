import axios from "./_base";
import errorHandler from "./_errorHandler";

export const changePassword = (vue, password, new_password, callback, errorCallback) => {
    axios.post('http://192.168.43.96/api/change_password', {
        password,
        new_password,
    }, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const users = (vue, page, search, callback) => {
    search = search.trim();
    let url = `http://192.168.43.96/api/users?page=${page}`;
    if (search !== '') {
        url = `${url}&search=${search}`
    }

    axios.get(url, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error)
    });
};

export const usersForGasStation = (vue, gasStationId, page, search, callback) => {
    search = search.trim();
    let url = `http://192.168.43.96/api/users_for_gas_station/${gasStationId}?page=${page}`;
    if (search !== '') {
        url = `${url}&search=${search}`
    }

    axios.get(url, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error)
    });
};

export const insertUser = (vue, name, email, mobile, role, password, blocked, callback, errorCallback) => {
    axios.post(`http://192.168.43.96/api/insert_user`, {
        name,
        email,
        mobile,
        role,
        password,
        blocked: (blocked ? 1: 0).toString(),
    }, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const updateUser = (vue, userId, name, email, mobile, role, password, blocked, callback, errorCallback) => {
    axios.post(`http://192.168.43.96/api/update_user/${userId}`, {
        name,
        email,
        mobile,
        role,
        password,
        blocked: (blocked ? 1: 0).toString(),
    }, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const getUser = (vue, id, callback, errorCallback) => {
    axios.get(`http://192.168.43.96/api/get_user/${id}`, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const deleteUser = (vue, id, callback, errorCallback) => {
    axios.post(`http://192.168.43.96/api/delete_user`, {
        id,
    }, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const blockUser = (vue, id, callback, errorCallback) => {
    axios.get(`http://192.168.43.96/api/block_user/${id}`, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};

export const unBlockUser = (vue, id, callback, errorCallback) => {
    axios.get(`http://192.168.43.96/api/unblock_user/${id}`, {
        headers: {
            'Authorization': `Bearer ${vue.$store.state.user.token}`,
        },
    }).then((response) => {
        callback(response.data);
    }).catch((error) => {
        errorHandler(vue, error, errorCallback)
    });
};
